
<template>
  <body
      data-spy="scroll"
      data-offset="60"
      data-target=".nav__holder"
      data-cookie="on"
      id="crowdfunding"
      itemscope
      itemtype="http://schema.org/WebPage"
    >
    
    <div class="masonry-wrapper heating">
      <div class="masonry home-services">
        <div class="masonry-item hero-image" >
          <i class="hero-overlay svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1915.29 539">
              <path class="hero-secondary" d="M1923.93 206.74L.79 544.88 1923.93 348z"/>
              <path class="hero-tertiary" d="M1118.23 433.32L-3.2-1v549z"/>
              <path class="hero-prime" d="M110.83 533.19l1817.84-187.25v193.61H-.45l.17-27.35 111.11 20.99z"/>
            </svg>
          </i>
          <h1 class="services-header-text">Heating</h1>
        </div>            
      </div>      
    </div>
    
    <main class="main-wrap">
      <div class="content-wrap">

        <section class="text-center">
          <!-- Introduction Container-->
          <div class="client-container mt-32 mb-16">
            <div class="text-center">
              <div class="client-container">                
                <div class="client-grid mb-10">
                  <div class="client-grid-column-50" >
                    <img class="services-img" src="/img/gallery/3.jpg" alt="" />
                  </div>
                  <div class="client-grid-column-50 card-body">
                    <h3 class="mb-32" >
                      All round maintenance for your commercial building
                    </h3>
                    <p class="client-service">
                      Our team of multi-skilled engineers are always on hand to provide the best service to ensure your heating facilities are all in perfect working order. As experts in the industry we are able to provide a range of services such as installation, servicing and all-round maintenance for your commercial heating supply. We cater for tourist attractions, warehouses, leisure facilities, hotels and much more. We can also provide a pre-planned maintenance programme and 24-our emergency call out service.
                    </p>
                    <h5 style="color:white;">
                      <router-link to="Contact" class=""><em>Contact us</em></router-link> 
                      to find out how our engineers can help you.
                    </h5>
                    <h6>
                      If you're after Domestic Heating, click here
                    </h6>
                    <div>
                      <router-link to="Heating">
                        <button class="client-button-orange-solid">
                          Click here
                        </button>
                      </router-link>                  
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="panel-grid-layout row  panel-grid-layout--overlap u-pad-top-0 u-pad-bottom-half u-bg-white u-align-left u-overflow-hidden mt-16 mb-80">
          <div class="panel-grid-container">
            <div class="panel-grid-wrapper">
              <h2 class="row-title">Services</h2>
              <div class="panel-grid heating panel-grid--left col-wrapper flex-wrapper col-wrapper--seamless">
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                     <h3 class="panel-title">Installation</h3>
                      <p class="panel-excerpt">Planning design of boiler rooms and heating systems. We can take care of all fitting needs from boilers systems to radiator requirements for large premises. Once fitted by our team we will then using our thorough testing services to ensure everything is as it should be.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Installation and Service Upgrades</h3>
                      <p class="panel-excerpt">Installation of <em style="color: #f87e35;">Water Heaters, Radiators, Furnace Burners and Oil Fired installations</em> plus all <em style="color: #f87e35;">Kitchen Interlock and Ventilation Systems</em>. Our team can take care of all pipework and underground gas supplies.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Service and Testing </h3>
                      <p class="panel-excerpt">All heating requirements including <em style="color: #f87e35;">Oil, LPG,</em> and <em style="color: #f87e35;">AHUs </em>along with <em style="color: #f87e35;">Catering facilities</em> and <em style="color: #f87e35;">Kitchen systems</em>.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Commercial Repairs and Maintenance</h3>
                      <p class="panel-excerpt">Repairs cover anything from <em style="color: #f87e35;">Water Heaters, Boilers, Oil, LPG</em> and <em style="color: #f87e35;">Underground Gas</em> supplies. Our team will also take care of <em style="color: #f87e35;">Gas and Water Leaks</em> and provide a solution for <em style="color: #f87e35;">Commercial Heating</em> and <em style="color: #f87e35;">Gas Issues</em>.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Safety Inspections </h3>
                      <p class="panel-excerpt">Our team visits your site to get to the route of any heating related issue. Our inspections will check and monitor anything from no heating or water to leakages and frozen pipes.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">                
                      <h3 class="panel-title">Intelligent Building Management Systems (IBMS & BMS) </h3>
                      <p class="panel-excerpt">By installing and maintaining <em>Building Management Systems</em> for our clients we ensure their utilities are working with maximum efficiency both economically and environmentally.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Reactive and Planned Maintenance</h3>
                      <p class="panel-excerpt">Ignite Facilities has a wealth of knowledge and experience in carrying out both planned and reactive maintenance works. By speaking to us in the first instance we can provide the best possible service tailored to your business needs.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Gas pressure testing and Purging</h3>
                      <p class="panel-excerpt">In order to keep clients legally compliant, Ignite Facilities carries out <em> Gas Testing and Purging</em> as part of the reporting that is legally required for any gas installations that are carried out.</p>
                    </div>
                  </div>	      	
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Air Source Heat Pumps</h3>
                      <p class="panel-excerpt">One of the ways businesses can save on their heating bills is through the installation and proper maintenance of <em>Air Source Heat Pumps</em>. Our engineers regularly carry out installations and servicing for our clients located across Staffordshire and Cheshire.</p>
                    </div>
                  </div>
                </div>
                <div class="panel-grid-item col--20">
                  <div class="panel  panel--link u-bg-grey ">
                    <div class="panel-body">
                      <h3 class="panel-title">Natural gas, LPG and Oil </h3>
                      <p class="panel-excerpt">Our engineers have a wealth of experience carrying out the installation and maintenance of boilers that use <em>Natural Gas, Liquefied Petroleum Gas or Oil</em>. If your business requires a dedicated facilities management company call us today and let Ignite Facilities look after your business. </p>
                    </div>
                  </div>	      	
                </div>
              </div>
            </div>
          </div>
        </section>  
        <!-- boilers and central heating 
        direct and indirect warm air 
        radiant heating 
        Energy efficiency monitoring and auditing -->

        <!-- Home about section -->
        <div class="masonry-wrapper heating-sub">
          <div class="masonry home-services benefits"> 
            <div class="overlay masonry-item">
              <div class="masonry-title mt-32">
                <h2>
                  Benefits
                </h2>
                <div class="services-ul services-grid">
                  <div>                    
                    <p> 
                      <img class="tick" src="/img/tick.png">
                      24 Hour Call Out
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Emergency Services
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Tailored Packages
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Out of Hours Call Out Number
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      National Coverage
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Planned Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Reactive Maintenance
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Full Electrical and Heating Installations
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Risk Assessment
                    </p>
                  </div>
                  <div>                    
                    <p>
                      <img class="tick" src="/img/tick.png">
                      Free Site Surveys
                    </p>
                  </div>
                </div>
                <div>
                  <router-link to="story">
                    <button class="client-button-orange-solid">
                      Contact Us
                    </button>
                  </router-link>                  
                </div>                
              </div>
            </div>             
          </div>
        </div>

        <CaseStudies />
      </div>
    </main>
  </body>
</template>

<script>
import CaseStudies from "@/components/ClientCarousel.vue";
export default {
  name: "Crowdfunding",
  metaInfo: {
    title: 'Heating Services - Trusted Heating Engineers in Stoke-on-Trent',
    meta: [
      { 
        vmid: 'title',
        property: 'og:title',
        content: 'Heating Services - Trusted Heating Engineers in Stoke-on-Trent | Ignite Facilities'
      },
      { 
        vmid: 'description',
        property:'og:description',
        content: 'Ignite Facilities offers trusted heating services in Stoke-on-Trent. Contact us for reliable heating solutions for your property.'
      }
    ]
  },
  components: {
    CaseStudies
  }
};
</script>

<style lang="scss">
.crowdfunding {
  .bg-cover {
    background-size: cover !important;
    background-position: center center !important;
    .container {
      padding: 12vh;
      h1 {
        color: white;
      }
    }
  }
}
</style>
